<template>
	<b-modal id="metamask-alert" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>

			</div>
			<h5>
				{{ $t('home.connectMetamask') }}
			</h5> 
			<div></div>

    </template>


		<div class="text-center"> 
			<h4>{{ $t('home.pleaseLogin') }}</h4>
		</div>
		<button class="btn btn-main mt-3" @click="reload()">{{ $t('home.reload') }}</button>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
import Vue from 'vue';
import VueQrcode from '@chenfengyuan/vue-qrcode'
 
Vue.component(VueQrcode.name, VueQrcode);

export default {
  name: 'Deposit',
  data: function() {
      return  {
      	
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    
   	},
   	methods: {
   		reload() {
			window.location.reload()
		}
   	},
   	beforeMount(){
 		},

}
</script>
 
<style scoped lang="scss">
	
	.deposit-container {
		    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	}

	
</style>
