<template>
  <div id="dashboard">
    <!-- <vue-metamask 
        userMessage="msg" 
        @onComplete="onComplete"
    >
    </vue-metamask> -->
    <Sidebar 
      :active="active" 
      v-if="showSidebar" 
      @close="toggleSidebar" 
      @logout="logout" 
      @view="viewPrivateKey"
      @import="importKey"
      @generate="generateKey('show')"
    /> 
    
    <div id="content">
      <div style="font-size:2.5rem;">
        <b-icon icon="arrow-bar-left" class="border rounded p-2" @click="toggleSidebar" style="cursor:pointer;"></b-icon>
      </div>
      <div class="d-flex justify-content-between m-3">
            <div>
              <img class="account" src="../assets/account.png"/> {{ init.profile.firstName }} {{ init.profile.lastName }} | {{ init.profile.emailAddress }}
            </div>
            <div class="form-group d-flex">
                <h6 class="my-auto ms-3">{{ $t('home.chooseLang') }}:</h6>
                <select 
                    class="form-control text-center" 
                    id="exampleFormControlSelect1"
                    style="
                        width:100px;
                        background-color:#231f20;
                        color:#c08000;
                        border:none;
                        margin-left:0.5em;
                    "
                    @change="changeLanguage()"
                    v-model="chosenLang"
                >
                    <option value="en">English</option>
                    <option value="kr">한국어</option>
                </select>
            </div>
        </div>
      <div> 
    </div>
      <div class="main-wallet" v-if="init.dashboardState == 'mainwallet'">
        <MainWallet :history="history" @mainwallet="setState('mainwallet')" @secondarywallet="setState('secondarywallet')"  @lockedtokens="setState('lockedtokens')" @send="send" @sendBonus="sendBonus"  @deposit="deposit" @coldStorage="coldStorage" @buy="buy" v-if="windowWidth >= 1300"/>

        <MobileMainWallet   @mainwallet="setState('mainwallet')" @secondarywallet="setState('secondarywallet')"  @lockedtokens="setState('lockedtokens')" @send="send" @sendBonus="sendBonus"  @deposit="deposit" @coldStorage="coldStorage" @buy="buy" v-else/>
      </div>

      <!-- <div class="main-wallet" v-if="init.dashboardState == 'secondarywallet'">
        <SecondaryWallet  :lockedTokens="lockedTokens" @mainwallet="setState('mainwallet')" @lockedtokens="setState('lockedtokens')" @sendBonus="sendBonus" @secondarywallet="setState('secondarywallet')"  v-if="windowWidth >= 1300"/>

        <MobileSecondaryWallet   :lockedTokens="lockedTokens" @mainwallet="setState('mainwallet')" @lockedtokens="setState('lockedtokens')" @sendBonus="sendBonus"  @secondarywallet="setState('secondarywallet')"  v-else/>
      </div> -->


      <div class="locked-tokens" v-if="init.dashboardState == 'lockedtokens'">
        <LockedTokens :lockedTokens="lockedTokens"  @mainwallet="setState('mainwallet')" @lockedtokens="setState('lockedtokens')" @secondarywallet="setState('secondarywallet')"  v-if="windowWidth >= 1300"/>
        <MobileLockedTokens :lockedTokens="lockedTokens" @mainwallet="setState('mainwallet')" @secondarywallet="setState('secondarywallet')"  @lockedtokens="setState('lockedtokens')" v-else />
      </div>

    </div>
    <ConfirmLogout />
    <PrivateKey />
    <GeneratePrivateKey :state="generateState"/>
    <ImportPrivateKey />
    <Send />
    <SendBonus />
    <Deposit />
    <ExportImport />
    <Buy />
    <AlertMetamask />
    <AlertInvalidWallet />
  </div>
</template>

<script>
  import { ethers, BigNumber } from 'ethers'
  import HomsToken from '../artifacts/HomsToken.json'

  import Sidebar from './Sidebar.vue'

  // UI Components
  import MainWallet from './WebView/MainWallet.vue'
  import LockedTokens from './WebView/LockedTokens.vue'
  import SecondaryWallet from './WebView/SecondaryWallet.vue'

  // Mobile Components
  import MobileMainWallet from './MobileView/MainWallet.vue'
  import MobileLockedTokens from './MobileView/LockedTokens.vue'
  import MobileSecondaryWallet from './MobileView/SecondaryWallet.vue'

  // Modals
  import ConfirmLogout from './modal/ConfirmLogout.vue'
  import PrivateKey from './modal/privateKey/PrivateKey.vue'
  import ImportPrivateKey from './modal/privateKey/ImportPrivateKey.vue'
  import GeneratePrivateKey from './modal/privateKey/GeneratePrivateKey.vue'
  import Send from './modal/Send.vue'
  import SendBonus from './modal/SendBonus.vue'
  import Deposit from './modal/Deposit.vue'
  import ExportImport from './modal/ExportImport.vue'
  import Buy from './modal/Buy.vue'

  // Alert
  import AlertMetamask from './modal/AlertMetamask.vue'
  import AlertInvalidWallet from './modal/AlertInvalidWallet.vue'
  
  
  export default {
    components: {
      Sidebar:  Sidebar,

      // UI Components
      MainWallet:  MainWallet,
      LockedTokens:  LockedTokens,
      SecondaryWallet:  SecondaryWallet,

      // Mobile Components
      MobileMainWallet:  MobileMainWallet,
      MobileLockedTokens:  MobileLockedTokens,
      MobileSecondaryWallet:  MobileSecondaryWallet,

      // Modals
      ConfirmLogout:  ConfirmLogout,
      PrivateKey:  PrivateKey,
      ImportPrivateKey:  ImportPrivateKey,
      GeneratePrivateKey:  GeneratePrivateKey,
      Send:  Send,
      SendBonus:  SendBonus,
      Deposit:  Deposit,
      ExportImport:  ExportImport,
      Buy:  Buy,

      // Alert
      AlertMetamask: AlertMetamask,
      AlertInvalidWallet: AlertInvalidWallet,
    },

    data() {
      return {
        showSidebar: false,
        state: 'mainwallet',
        active: 'dashboard',
        generateState: "",
        msg: 'test',
        windowWidth: window.innerWidth,
        targetNetwork: '',
        targetNetworkId: '',
        history: {
          transfers: [],
          transactions: []
        },
        lockedTokens: {
          totalAllocation: '',
          upfrontReleased: '',
          totalClaimed: '',
          totalDuration: '', 
          vestingCycle:''
        },
        chosenLang: localStorage.getItem('lang')
        }
    },

    computed: {
      init() {
          return this.$store.state.init;
      },
    },

    methods: {
        changeLanguage() {
            localStorage.setItem('lang', this.chosenLang),
            window.location.reload();
        },
      onComplete(data) {
        this.init.metamask = data
        console.log(this.init.metamask)
      },

      toggleSidebar() {
        this.showSidebar = !this.showSidebar
      },

      logout: function(){
        this.$bvModal.show('logout-modal'); 
      },

      send: function() {
        this.init.labelSend = "Send";
        this.$bvModal.show('send-modal'); 
      },

      sendBonus: function() {
        this.init.labelSend = "Send";
        this.$bvModal.show('send-bonus-modal'); 
      },

      deposit: function() {
        this.$bvModal.show('deposit-modal'); 
      },

      coldStorage: function(){ 
        this.$bvModal.show('export-import-modal'); 
      },

      buy: function(){ 
        this.$bvModal.show('buy-modal'); 
      },

      viewPrivateKey() {
        this.active="privatekey";
        console.log(this.active)
        console.log('..')
        this.init.viewPrivKey.state = 'pin';
        this.$bvModal.show('pin-key-modal'); 
      },

      generateKey(isShown){
        this.active="privatekey";
        this.generateState = isShown
        this.$bvModal.show('generate-key-modal');
        setTimeout(()=>{
          this.generateState = ""
        },5000);
      },

      importKey: function(){
        this.active="privatekey";
        this.$bvModal.show('import-key-modal'); 
      },

      setState(state) {
        console.log('.')
        this.init.dashboardState = state
        console.log("this.init.dashboardState: " + this.init.dashboardState)
      },

      padWithZeros(str, targetLength) {
          while (str.length < targetLength) {
              str = '0' + str;
          }
          return str;
      },
      getWalletAddress() {
          const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '  /cx/cryptoaddress/HOMS',
          };
          this.axios(options)
            .then((response) => { 
              
                
               
               setTimeout(() => {

                this.init.wallet.address = response.data.addressValue
              this.init.wallet.privKey = response.data.memo;

              this.getBlxqBalance();
 

                 let smartContract = this.init.isTest ? "0x3219B80A6Cf1050aCd84D7B73fb03237556BeE21" : "0x8D377F0F1E2B7bcDcC1d6Db371C61DC7cF3de808";
                 let jsonRpc = this.init.isTest ? "https://testnetq1.blx.org" : "https://mainnet.blxq.org";

               const provider = new ethers.providers.JsonRpcProvider(jsonRpc);

 


                let privateKey = this.padWithZeros(window.atob(this.init.wallet.privKey), 64);
                const wallet = new ethers.Wallet(privateKey, provider);
                

                 const signer = wallet.provider.getSigner(wallet.address);
 
                

                this.init.contract = new ethers.Contract(
                  // process.env.VUE_APP_ORIGIN_TOKEN_ADDRESS,
                  smartContract,
                  HomsToken.abi,
                  signer
                );


               this.checkBalance();
              this.getAlloc();
              this.getTotalClaim();
              this.getLatestRelease();
              this.getTotalDuration();


               },500)

                
           
            })
            .catch((error) => {
              
            })
          },

           getBlxqBalance() {
      

            const options = {
                method: 'GET',  
                url: '/blxq-api/v2/addresses/' + this.init.wallet.address,
            };
            this.axios(options)
                .then((response) => {
                    let toWei =  1000000000000000000;
                   this.init.wallet.blxqBalance = response.data.coin_balance/toWei; 
                   this.init.blxqBalanceLoading = false;
                })
                .catch((error) => {

                    console.log(error)
                });
        },

      getProfile: function(){
        const options = {
            method: 'GET',
            headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'blox-authz': localStorage.getItem("tokenId"),
          }, 
            url: '/cx/profile/',
        };

        this.axios(options)
          .then((response) => {
              this.init.profile = response.data;
 
              if(this.init.profile.metamask == null || this.init.profile.metamask == '') {
                this.getWalletAddress();
              } else {

                const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
                const signer = provider.getSigner()
 

                 let smartContract = this.init.isTest ? "0x3219B80A6Cf1050aCd84D7B73fb03237556BeE21" : "0x8D377F0F1E2B7bcDcC1d6Db371C61DC7cF3de808"; 



                this.init.contract = new ethers.Contract(
                  // process.env.VUE_APP_ORIGIN_TOKEN_ADDRESS,
                  smartContract,
                  HomsToken.abi,
                  signer
                );


                this.checkNetwork(); 

                 provider.on("network", (newNetwork, oldNetwork) => {
                    // When a Provider makes its initial connection, it emits a "network"
                    // event with a null oldNetwork along with the newNetwork. So, if the
                    // oldNetwork exists, it represents a changing network
                    if (oldNetwork) {
                        window.location.reload();
                    }
                });

                window.ethereum.on('accountsChanged', function (accounts) {
                window.location.reload();
              })

                
              }

          })
      },

      addressShort(address) { 
        return `${address.slice(
          0,
          2
        )}...${address.slice(-4)}`
      },

      formatDifferenceInHours(date1, date2) {
        let currentDateTime = new Date(date1 * 1000);
        let rawDate = new Date(date2 * 1000)

        const timeDifferenceMs = rawDate - currentDateTime;

        const hoursDifference = timeDifferenceMs / (1000 * 60 * 60);

        return hoursDifference + " Hours";

      },

      getTotalDuration() {
        let schedule = [
            '1711814400',
            '1719676800',
            '1727625600',
            '1735574400',
            '1743350400',
            '1751212800',
            '1759161600',
            '1767110400',
            '1774886400',
            '1782748800',
          ];

        let getLatestDateOfVesting = 0; 

        schedule.forEach((el,i) => {
          let currentTime = Math.floor(Date.now() / 1000);
 
          if(currentTime >= schedule[i] &&  currentTime <= schedule[i+1]) {
            
            getLatestDateOfVesting = schedule[i+1];
 
          }

          
        })


        this.lockedTokens.totalDuration = getLatestDateOfVesting == 0 ? schedule[0] : getLatestDateOfVesting;

        console.log("i am here " + this.lockedTokens.totalDuration);

        this.lockedTokens.vestingCycle = this.formatDifferenceInHours(schedule[0], schedule[schedule.length - 1]) 

      },



      async checkBalance() { 

        console.log(this.init.wallet.address)
        let balance = await this.init.contract.balanceOf(this.init.wallet.address)
        balance = ethers.utils.formatUnits(balance, 18)
        console.log('balance :>> ', balance)
        this.init.wallet.balance = balance
         
      },

      async getAlloc() {
        let totalAlloc = await this.init.contract.getTotalAmountPurchase(this.init.wallet.address)
        totalAlloc = ethers.utils.formatUnits(totalAlloc, 18)
        console.log('alloc :>>', totalAlloc)
        this.lockedTokens.totalAllocation = totalAlloc
        if(totalAlloc > 0.0) {
          this.init.dashboardState = 'lockedtokens'
        }
      },

      async getTotalClaim() {
        let totalClaimed = await this.init.contract.getTotalRelease(this.init.wallet.address)
        totalClaimed = ethers.utils.formatUnits(totalClaimed, 18)
        console.log('totalClaimed :>>', totalClaimed)
        this.lockedTokens.totalClaimed = totalClaimed
      },

      async getLatestRelease() {
        let upfrontReleased = await this.init.contract.getLatestRelease(this.init.wallet.address)
        upfrontReleased = ethers.utils.formatUnits(upfrontReleased, 18)
        console.log('upfrontReleased :>>', upfrontReleased)
        this.lockedTokens.upfrontReleased = upfrontReleased
      },
      async connectWallet(){
        try {
         
          const data = await window.ethereum.request({
            method: 'eth_requestAccounts',
          })
          console.log('data :>> ', data)

          this.init.wallet.address = data[0];
          console.log('adress :>>', this.init.wallet.address)
          this.init.wallet.network = this.targetNetwork;
          this.init.wallet.isConnected = true;
          this.init.metamask.connected = true;
          this.getBlxqBalance();
          this.checkBalance();
          this.getAlloc();
          this.getTotalClaim();
          this.getLatestRelease();
          this.getTotalDuration();

          if(this.init.profile.metamask != this.init.wallet.address) {
              this.$bvModal.show('invalidwallet-alert');
            }
        
          console.log('DApp connected to your wallet 💰')
        } catch (error) {
          // const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
          // // Prompt user for account connections
          // await provider.send("eth_requestAccounts", []);
          // const signer = provider.getSigner();
          // console.log("Account:", await signer.getAddress());
          // this.init.metamask.connected = false;
          console.error('Error connecting DApp to your wallet')
          console.error(error)
          this.$bvModal.show('metamask-alert');
        }
      },


      onResize() {
        this.windowWidth = window.innerWidth
      },

       async checkNetwork() {
        console.log(`Target network is ${this.targetNetworkId}`)

        if (window.ethereum) {
          const currentChainId = await window.ethereum.request({
            method: 'eth_chainId',
          })
          console.log('Current network  :>> ', currentChainId)

          if (currentChainId == this.targetNetworkId) {
            this.connectWallet();
            console.log('adress :>>', this.init.wallet.address)
            this.init.wallet.networkOk = true;
            
          } else {
            

            const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            // Prompt user for account connections
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            console.log("Account:", await signer.getAddress());

            this.init.wallet.networkOk = false 
            this.$bvModal.show('metamask-alert');
          }
        }
      },
      checkSession: function() {
        const options = {
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'blox-authz': localStorage.getItem("tokenId"),
          }, 
          url: '/cx/sessioncheck',
        };

        this.axios(options)
          .then((response) => { 
            this.getProfile();
            }).catch((err) => {
                
              if(err.response.data.msgCode == '00001') {
                window.location.href = "/login";
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                  window.location.href = "/setup-profile";
                },1000)
                
              }  

            })
      },
    },

    mounted() {
      this.targetNetworkId = this.init.isTest ? "0x453" : "0x454";
      this.checkSession();
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
    },

    beforeDestroy() { 
      window.removeEventListener('resize', this.onResize); 
    },

   

  beforeMount() {
     

      // this.checkNetwork(); 

      // Force page refreshes on network changes
       
    },
    
    watch: {
      'init.swap': function(newValue, oldValue) {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()

        if (newValue != oldValue) {
          if(newValue) {

            this.init.contract = new ethers.Contract(
              process.env.VUE_APP_DESTINATION_TOKEN_ADDRESS,
              DestinationToken.abi,
              signer
            );
      
            this.checkNetwork(); 

          } else {
            this.init.contract = new ethers.Contract(
              process.env.VUE_APP_ORIGIN_TOKEN_ADDRESS,
              HomsToken.abi,
              signer
            );
      
            this.checkNetwork(); 
          }
        }
      },
      }
  }
</script>

<style scoped lang="scss">
  #content { 
    color:white;
    background-color:black; 
    padding:40px;
    min-height:100vh;

    .main-wallet, .locked-tokens { 
      padding:40px 0px;
    }
  }
  .account{
    width: 2rem;
  }

  @media only screen and (max-width: 600px) {
        #content {
            padding: 1em !important;
        }
    }
</style> 
