<template>
	<b-modal id="send-bonus-modal" hide-footer  hide-header-close no-close-on-backdrop size="lg" >

	<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" class="icon"/>
			</div>
			<h5>
				{{ $t('modal.send.sendHoms') }}
			</h5> 
			<div></div>

    </template>
	<div v-if="state == 'send'"> 
		<div class="send-container" >
			<form>
				 <div class="text-center"> <span class="bold"> {{ $t('modal.send.balance') }}: </span> {{ formatAmount(init.wallet.bonusUnlockedBalance) }}  </div>

			 <div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
			  

				<hr>

			

				<div class="form-container">
						<div class="note" v-if="send.amount > 100">{{ $t('modal.send.testTxnNote') }}</div>
					 <label for="receipt">{{ $t('modal.send.recipientAddress') }}:</label>
				  <input type="text" id="receipt" name="receipt" v-model="send.address" class="form-control" minlength="30" maxlength="66">
				  <span class="error" v-if="errors.address != ''"> {{ errors.address }} </span> 
				 </div>
			 
			  
			  <div class="form-container">
				  <label for="ampunt">{{ $t('modal.send.amount') }}:</label>
				  <input type="text" id="amount" name="amount" v-model="send.amount" class="form-control">
				  <span class="error" v-if="errors.amount != ''"> {{ errors.amount }} </span> 
			  </div>
			   
			  
			  <div class="flex  margin-top">
				<button  class="btn btn-main" type="submit" :disabled="isSendDisabled" v-on:click.prevent="onSend()">  
					<span> {{ $t('modal.send.send') }}</span>	
				</button> 
			</div>
			 
			
			
			</form>
		</div> 
	</div>
	<div v-if="state == 'pin'"> 
		<form  v-on:submit.prevent="verifyPin" class="text-center">
			<p class="text-center">{{ $t('modal.send.enterYourPin') }}:</p>
			<div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
			<div class="d-flex justify-content-center">
				<div class="pin-wrapper" >
					<div>
					  <input id="pin1" type="password" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin2"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin3" type="password" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin4"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
					</div>

					<div>
						<input id="pin5" type="password" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
					  </div>
			
					  <div>
						<input id="pin6"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
					  </div>
		  
				  </div> 
			</div>
	
			<div class="form-group ">
				<button type="submit" class="btn btn-send">{{ $t('modal.send.submit') }}</button>
			</div>

		</form>
	</div>
	<div v-if="state == 'otp'"> 
		<form  v-on:submit.prevent="verifyOtp">
	
			<p class="text-center">{{ $t('modal.send.enterOneTimePin') }} {{ maskOtp }} :</p>
			<div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
			<div class="d-flex justify-content-center">
				<div class="otp-wrapper" >
				<div>
					<input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
				</div>
				</div>
			</div>
	<div class="text-center">
	  <p @click="resendCode" v-if="!ifResent" style="cursor:pointer;">{{ $t('modal.send.no2FAMsg1') }}  <span style="text-decoration: underline;">{{ $t('modal.send.no2FAMsg2') }}</span></p>
	  <p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
			</div>
	
			<div class="form-group text-center">
				<button type="submit" class="btn btn-send"  :disabled="isOtpDisabled">{{ $t('modal.send.submit') }}</button>
			</div>
		</form>
	</div>
	<div v-if="state == 'success'"> 
		<div class="text-center">
			<h5 v-if="localStorage.getItem('lang') == 'kr'">
				이 주소로 HOMS {{ formatAmount(successInfo.amount) }}개를 성공적으로 보냈습니다
			</h5>
			<h5 v-else>
			You successfully sent {{ formatAmount(successInfo.amount) }} HOMS to 
			</h5>
			<h5>{{ successInfo.recipient }}</h5>
			<button class="btn btn-main" @click="closeReload">{{ $t('modal.send.done') }}</button>
		</div>
	</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
import { ethers, BigNumber } from 'ethers'

export default {
  name: 'Send',
  data: function() {
      return  {
      	send: {
					address: "",
					amount: "",
					privateKey: ""
				},
				showError: false,
				errors: {
					address: "",
					amount: "",
					privateKey: ""
				},
				state: 'send',
		ifResent: true,
		timerInSeconds: 60,
		pinArr: [],
		otpArr: [],
		systemMsg: "",
		showError: false,
		successInfo: {},
		isOtpDisabled: false,
		isSendDisabled: false,
		trxInProgress: false,
		network: '',
		networkList: {},
		depositAddress: '',
		chosenNetwork: 'BLXQ',
		maskOtp: "",
		showInput: true,
      }
    },
	components: {
		
	},
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		close: function(){
			
   			this.$bvModal.hide('send-bonus-modal'); 
			   this.state = 'send'
			   this.pinArr = []
			   this.otpArr = []
			   this.errors.address = ""
			   this.errors.amount = ""
			   this.errors.privateKey = ""
			   this.isOtpDisabled = false
   		},
		closeReload: function(){
			
			window.location.reload();
		},
   		formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },

      getBonusBalance() {

  
            const options = {
                method: 'GET',
                url: '/cx/funds',
            };

            this.axios(options)
            .then((response) => {
             

             let bonusFunds = response.data.filter(fund => fund.currency === "HOMSB")[0]; 
          
 
               if(bonusFunds == undefined) {
                bonusFunds = {};
                bonusFunds.availableBalance = 0;
               }

            this.init.wallet.bonusBalance = bonusFunds.availableBalance;
               

            })
        },

	  nextStep() {
		  let hasError = false
		  if(this.send.address == "") {
			  hasError = true
			  this.errors.address = this.i18n.t('modal.send.errors.isRequired');
		  } else {
		  	if(!ethers.utils.isAddress(this.send.address)) {
		  		hasError = true
		  		this.errors.address = this.i18n.t('modal.send.errors.invalidWalletAddress');
		  	}
		  }
		  if(this.send.amount == "") {
			  hasError = true
			  this.errors.amount = this.i18n.t('modal.send.errors.isRequired');
		  }

		  if(hasError == false) {
		this.state = 'pin'
		this.errors.address = ""
		this.errors.amount = ""
		this.errors.privateKey = ""
		  } else {
			  console.log(hasError)
		  }
	  },

	  resendCode: function() {
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/private/resend2fa',
          };

          this.axios(options)
            .then((response ) => { 
              this.systemMsg = ""
              this.showError = false
              this.ifResent = true
              console.log(this.ifResent)
              this.timer();
            }).catch((err) => {
                console.log('resend code errror')
                console.log(err)
            })
        },

      timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },

		verifyPin() {
            const params = {
                pin: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validatepin',
                };
                this.axios(options)
                    .then((response) => {

                    	this.maskOtp = response.data.msg;
						this.state = 'otp'
                      this.clearError();
                      this.timer();
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.pinArr = []
						this.showError = true
						this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
						},10000);
					});

        },

		clearError() {
			this.systemMsg = ""
			this.showError = false
		},

		verifyOtp() {
			this.isOtpDisabled = true
			const params = {
                otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validate2fa',
                };
                this.axios(options)
                    .then((response) => {
                    this.state = 'send';
					this.isOtpDisabled = false
								this.confirmSend();
					 this.clearError();
                    })
                    .catch((error) => {
                        // Error code goes here
						this.isOtpDisabled = false
						this.showError = true
            			this.otpArr = []
                        this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
					},10000);
				});
        },

       

		isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      otpBack: function(event, currrentBox) {
		if(this.state == 'pin') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("pin"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("pin"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		} else if(this.state == 'otp') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		}
      },


      submitSend: function() {

      	const params = {
              sourceAmount: this.send.amount,
              outboundCryptoAddr: this.send.address,
              sourceCurrency: this.init.homsBonusSymbol,
              targetCurrency: this.init.homsBonusSymbol,
              txnControlId: this.send.txnControlId
              }

              const data = Object.keys(params)
              .map((key) => `${key}=${encodeURIComponent(params[key])}`)
              .join('&');


         const options = {
              method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded' },
              data,
              url: '/cx/txn/cryptotransfer/submit',
              };

          this.axios(options)
            .then((response ) => {  
              //this.confirmSend();

              this.nextStep();
            }).catch((error) => {

            	if(error.response.data.msgText == "Field validation errors") {
            		let errorCode = error.response.data.fieldErrors[0].code;
            		if( errorCode == 'INSUFFICIENT_BONUS') {
            			this.systemMsg = this.i18n.t('modal.send.errors.insufficientAvailableBonus');
            		} else {
            			this.systemMsg = error.response.data.fieldErrors[0].defaultMessage
            		}
	               
	            } else   {


	            	this.systemMsg = err.response.data.msgText;
	            }


              
										this.showError = true;

										setTimeout(() => {
											this.systemMsg = '';
											this.showError = false;
											this.isSendDisabled = false;
											 
										},3000)
            })
        },


        confirmSend: function() {

      		const params = {
              txnControlId: this.send.txnControlId
              }

              const data = Object.keys(params)
              .map((key) => `${key}=${encodeURIComponent(params[key])}`)
              .join('&');


         const options = {
              method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded' },
              data,
              url: '/cx/txn/cryptotransfer/confirm',
              };

          this.axios(options)
            .then((response ) => {  
            	this.getBonusBalance()
               this.send.amount = "";
										this.send.address = "";
										this.systemMsg = this.i18n.t('modal.send.transactionRequestSent');
										window.location.href = "/history";
										this.showError = true;

										setTimeout(() => {
											this.systemMsg = '';
											this.showError = false;
											this.isSendDisabled = false;
											 
										},3000)
            }).catch((error) => {

            	console.log(error)
            })
        },

      async onSend() {
		this.isSendDisabled = true;
		  let hasError = false;
				
				if(this.send.address == '' || this.send.address == undefined || this.send.address == null) {
					this.errors.address = this.i18n.t('modal.send.errors.noRecipientAddress');;
					hasError = true;
				}

				if(this.send.address == this.init.address.addressValue) {
					this.errors.address = this.i18n.t('modal.send.errors.noSendToOwn');
				}
				
				if(this.send.amount == '' || this.send.amount == undefined || this.send.amount == null) {
					this.errors.amount = this.i18n.t('modal.send.errors.amountRequired');;
					hasError = true;
				} else {
					if(this.send.amount < 0) {
						this.errors.amount = this.i18n.t('modal.send.errors.amountRequired');;
						hasError = true;
					}
				}

				if(!hasError) {
						this.showError = false; 
	

              const options = {
              method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded' },
              url: '/cx/txn/cryptotransfer/init',
              };
              this.axios(options)
                  .then((response) => {
                  	this.send.txnControlId = response.data.txnControlId;
                  	this.submitSend();
                	 
                  })
	              .catch( async (err) => {
	                 console.log(err)

									});


				}

	  },



   	},
   	mounted(){

 		},

}
</script>
 
<style scoped lang="scss">
	.note {
		font-style: italic;
		color: red;
		font-size: 0.8em;
	}
	.flex {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		img {
			width: 30px;
		}
	}


	  .send-container { 
	   
	    border-radius: 6px;
	    flex-direction: column;
	    position: relative;
	    background: #FFF; 
	    color: #000;
  }

  

   .error {
  	color: red;
  }

  button {
  	margin-top:1em;
  }

  .btn-send {
   background-color: #c08000 !important; 
   color:white;
   width:100%;
   margin-top:10px;
 }

  .otp-wrapper, .pin-wrapper {
      display: flex;
      width: 80%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
			  padding-bottom:7.5px;
      }
    }
	}

	.go-back {
		cursor:pointer; 
		&:hover {
			text-decoration:underline;
		}
	}


	
</style>
