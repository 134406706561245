<template>
    <div class="d-flex flex-wrap justify-content-between">
        <div class="left">
            <div class="header d-flex flex-wrap justify-content-between">
                <div class="d-flex flex-wrap justify-content-around wallet-buttons">
                    <button class="btn btn-wallet" :class="{active: init.dashboardState == 'lockedtokens'}" @click="initFunction('lockedtokens')">{{ $t('home.lockedTokens') }}</button>
                    <button class="btn btn-wallet" :class="{active: init.dashboardState == 'mainwallet'}" @click="initFunction('mainwallet')">{{ $t('home.wallet') }}</button>
                   
                    <!-- <button class="btn btn-wallet" :class="{active: init.dashboardState == 'mainwallet'}" @click="initFunction('mainwallet')">NFTs</button> -->
                </div>
             
            </div>
            <div class="main-wrapper">
               
               <div class="recent-transactions">
                    <div class="heading">{{ $t('home.homsTokens') }}</div>
                    
                    <div class="d-flex flex-wrap justify-content-between mb-3">




                        <div >
                            <div class="vested-info d-flex flex-wrap mb-2">
                                <img src="../../assets/allocation.png" alt="" class="my-auto" v-b-tooltip.hover title="The value of all HOMS token bought">
                                <div class="vested-text">
                                    <p class="vested-value"> {{ formatAmount(lockedTokens.totalAllocation)}} </p>
                                    <p class="label">{{ $t('home.totalPurchase') }}</p>
                                </div>
                            </div>

                            <div class="vested-info d-flex flex-wrap mb-2">
                                <img src="../../assets/coin.png" alt="" class="my-auto" v-b-tooltip.hover title="The number of all HOMS token received">
                                <div class="vested-text">
                                    <p class="vested-value">  {{ formatAmount(lockedTokens.totalClaimed)}} </p>
                                    <p class="label">{{ $t('home.totalClaim') }}</p>
                                </div>
                            </div>
                            
                        </div>
                        <div >
                            <div class="vested-info d-flex flex-wrap mb-2">
                                <img src="../../assets/claimable.png" alt="" class="my-auto" v-b-tooltip.hover title="The number of HOMS token to be released on the next vesting schedule">
                                <div class="vested-text">
                                    <p class="vested-value">{{ formatAmount(lockedTokens.upfrontReleased)}}</p>
                                    <p class="label">{{ $t('home.upfrontRelease') }}</p>
                                </div>
                            </div>
                            
                            <div class="vested-info d-flex flex-wrap mb-2">
                                <img src="../../assets/vesting cyle.png" alt="" class="my-auto" v-b-tooltip.hover title="The number of hours to complete the vesting schedule">
                                <div class="vested-text">
                                    <p class="vested-value">{{ lockedTokens.vestingCycle }} </p>
                                    <p class="label">{{ $t('home.vestingCycle') }}</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div class="history-content">
                    <table>
                        <tr>
                            <th>{{ $t('home.timestamp') }}</th>
                            <th>{{ $t('home.transactionHash') }}</th>
                            <th>{{ $t('home.input') }}</th>
                            <th>{{ $t('home.output') }}</th>
                            <th>{{ $t('home.amount') }}</th>
                            
                            
                        </tr>
                        <tr v-for="txn in historyList" :key="txn.tx_hash" v-if="txn.method == 'vest'">
                            <td>{{ new Date(txn.timestamp).toLocaleString() }}</td>
                            <td class="th-orange"> <div class="hash"><a :href="'https://explorer.blx.org/tx/' + txn.tx_hash" target="_blank"> {{ txn.tx_hash }}</a> </div> </td>
                            <td> {{ txn.from.hash }} </td>
                            <td> {{ txn.to.hash }} </td>
                            <td> {{ formatAmount( formatEther(txn.total.value) ) }} {{ txn.token.symbol }} </td>
                            
                        </tr>
                        <tr>
                            <td colspan="5" v-if="historyList.length == 0"> {{ $t('home.noTransactions') }} </td>
                        </tr>
                    </table>
                </div> 

                 

                <!--  <div class="main-wrapper">
                    <div class="wallet-row d-flex flex-wrap justify-content-evenly">
                        <div class="info-card d-flex flex-wrap flex-column justify-content-center mb-5">
                           <div class="wallet-address mt-5">{{ init.wallet.address }}</div>
                            <div class="label">{{ $t('home.walletBalance') }}</div>
                            <div class="currency">HOMS <span class="balance">{{ formatAmount(init.wallet.bonusBalance) }}</span></div>
                        </div>
                        <div class="function-row d-flex flex-wrap justify-content-around mt-5">
                            <div class="function-card text-center text-white d-flex flex-column justify-content-around" @click="initFunction('sendBonus')">
                                <div class="function-img">
                                    <img src="../../assets/send.png" alt="">
                                </div>
                                <div class="label">{{ $t('home.send') }}</div>
                            </div>
                           
                        </div>
                    </div> 
                </div> -->


            </div>
        </div>
        <div class="right">
            
            <div class="main-wrapper">
                     <h6>{{ $t('home.vestingSchedule') }}</h6>
                    <div class="container countdown-time d-flex flex-wrap justify-content-around">
                      <div class="card card-countdown countdown-time-days">
                        <div class="countdown-time-num">{{timerInDays}}</div>
                        <div class="countdown-time-text">Days</div>
                      </div>
                      <div class="card card-countdown countdown-time-hms">
                          <div class="countdown-time-num">{{timerinHours}}</div>
                          <div class="countdown-time-text">Hrs</div>
                      </div>
                      <div class="card card-countdown countdown-time-hms">
                          <div class="countdown-time-num">{{timerInMinutes}}</div>
                          <div class="countdown-time-text">Mins</div>
                      </div>
                      <div class="card card-countdown ountdown-time-hms">
                          <div class="countdown-time-num">{{timerInSeconds}}</div>
                          <div class="countdown-time-text">Sec</div>
                      </div>
                    </div>
                </div>

            <div class="vesting-sched-wrapper">
                <div class="heading">VESTING SCHEDULE</div>
                <table>
                    <colgroup>
                        <col span="1" style="width: 30%;">
                        <col span="1" style="width: 40%;">
                        <col span="1" style="width: 30%;">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>Quarter</th>
                            <th>Vesting % (of the total purchased tokens)</th>
                            <th>Date</th>
                        </tr>
                        <tr>
                            <td>Q1 - 2024</td>
                            <td>5%</td>
                            <td>March 31, 2024</td>
                        </tr>
                        <tr>
                            <td>Q2 - 2024</td>
                            <td>5%</td>
                            <td>June 30, 2024</td>
                        </tr>
                        <tr>
                            <td>Q3 - 2024</td>
                            <td>5%</td>
                            <td>Sept 30, 2024</td>
                        </tr>
                        <tr>
                            <td>Q4 - 2024</td>
                            <td>5%</td>
                            <td>Dec. 31, 2024</td>
                        </tr>
                        <tr>
                            <td>Q1 - 2025</td>
                            <td>10%</td>
                            <td>March 31, 2025</td>
                        </tr>
                        <tr>
                            <td>Q2 - 2025</td>
                            <td>10%</td>
                            <td>June 30, 2025</td>
                        </tr>
                        <tr>
                            <td>Q3 - 2025</td>
                            <td>10%</td>
                            <td>September 30, 2025</td>
                        </tr>
                        <tr>
                            <td>Q4 - 2025</td>
                            <td>10%</td>
                            <td>December 31, 2025</td>
                        </tr>
                        <tr>
                            <td>Q1 - 2026</td>
                            <td>20%</td>
                            <td>March 31, 2026</td>
                        </tr>
                        <tr>
                            <td>Q2 - 2026</td>
                            <td>20%</td>
                            <td>June 30, 2026</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import numeral from 'numeral'
import { ethers, BigNumber } from 'ethers'
export default {
    data() {
        return {
            historyList: [],
            bonusFunds: {
                availableBalance: 0
            },
            timerInSeconds: 0,
            timerInMinutes: 0,
            timerinHours: 0,
            timerInDays: 0,
        }
    },
    methods: {
        
        formatAmount: function(num){
            return numeral(num).format('0,0.00000000');
        },
        formatEther: function(num) {
            return ethers.utils.formatUnits(num, 18);
        },
        getHistory: function() {
 

        let smartContract = this.init.isTest ? "0x3219B80A6Cf1050aCd84D7B73fb03237556BeE21" : "0x8D377F0F1E2B7bcDcC1d6Db371C61DC7cF3de808";

            const params = {
              type: 'ERC-20,ERC-721,ERC-1155',
              filter: 'to | from',
              token: smartContract
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');

            const options = {
                method: 'GET',
                params,
                headers: {
                    'accept': 'application/json' ,
                    'content-type': 'application/json' ,
                },
                url: '/blxq-api/v2/addresses/' + this.init.wallet.address + '/token-transfers?',
            };

            this.axios(options)
              .then((response) => {
                  console.log(response)
                  this.historyList = response.data.items;
              }).catch((err) => {
                console.log(err);
                this.historyList = [];
              })
          },
            async connectWallet(){
                try {
                 
                  const data = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                  })
                  console.log('data :>> ', data)
 
                  window.location.reload()
                 
                } catch (error) {
                 console.log(error)
                }
              },
        formatDifferenceInHours(date) {
            let currentDateTime = Date.now();
            let rawDate = new Date(date * 1000)

            const timeDifferenceMs = rawDate - currentDateTime;

            const hoursDifference = timeDifferenceMs / (1000 * 60 * 60);

            return hoursDifference.toFixed(2) + " Hours";

          },
        setState() {
            this.$emit('setState')
        },
        initFunction(func) {
            this.$emit(func)
        },

        checkVestingDate() {
            let currentDate = Math.floor(Date.now() / 1000);
            if(currentDate > 1711814400 &&  currentDate < 1719676800) {
                return 1719676800;
            } 

            // second vesting 6 to 9
            else if(currentDate > 1719676800 &&  currentDate < 1727625600) {
                return 1727625600;
            } 

            // third vesting 9 to 12
            else if(currentDate > 1727625600 &&  currentDate < 1735574400) {
                return 1735574400;
            } 
            //12 to 3
            else if(currentDate > 1735574400 &&  currentDate < 1743350400) {
                return 1743350400;
            }

            //3 to 6
            else if(currentDate > 1743350400 &&  currentDate < 1751212800) {
                return 1751212800;
            }

            //6 to 9
            else if(currentDate > 1751212800 &&  currentDate < 1759161600) {
                return 1759161600;
            }

            //9 to 12
            else if(currentDate > 1759161600 &&  currentDate < 1767110400) {
                return 1767110400;
            }

            //12 to 3
            else if(currentDate > 1767110400 &&  currentDate < 1774886400) {
                return 1774886400;
            }

            //3 to 6
            else if(currentDate > 1774886400 &&  currentDate < 1782748800) {
                return 1782748800;
            }

            //6 to 9
            else if(currentDate > 1782748800 &&  currentDate < 1790697600) {
                return 1790697600;
            }  else {
                return 0;
            }
        },

         getDateComponents(timestamp) {
            // Convert the timestamp to milliseconds by multiplying by 1000
            const date = new Date(timestamp * 1000);

            // Get the year, month, and date
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Months are zero-indexed in JavaScript
            const day = date.getDate();

            return {
                year: year,
                month: month,
                day: day
            };
        },
          showRemaining(){

            console.log(this.checkVestingDate() )
          const timer = setInterval(() => {
            const now = new Date();
            const end = new Date(this.getDateComponents(this.checkVestingDate()).year, this.getDateComponents(this.checkVestingDate()).month, this.getDateComponents(this.checkVestingDate()).day, 24, 0, 0, 60);
            const distance = end.getTime()  - now.getTime();

            if(distance< 0){
              clearInterval(timer);
              return;
            }

            const days = Math.floor(distance / this._days);
            const hours = Math.floor((distance % this._days) / this._hours);
            const minutes = Math.floor ((distance % this._hours) / this._minutes);
            const seconds = Math.floor ((distance % this._minutes) / this._seconds);

            this.timerInMinutes = minutes < 10 ? "0" + minutes: minutes;
            this.timerInSeconds = seconds < 10 ? "0" + seconds: seconds;
            this.timerinHours = hours < 10 ? "0" + hours: hours;
            this.timerInDays = days < 10 ? "0" + days: days;
            this.loaded = true;
          }, 1000)
        },

    },
    computed: {
      init() {
          return this.$store.state.init;
      },
      _seconds:() => 1000,
        _minutes(){
          return this._seconds * 60
        },
        _hours(){
          return this._minutes * 60
        },
        _days(){
          return this._hours * 24
        },
    },
    props: {
        state: '',
        lockedTokens: {}
    },
    mounted() {
        this.showRemaining();
        this.getHistory();
    }
}
</script>

<style scoped lang="scss">

    .connect-metamask-wrapper {
        background: #FFFFFF;
        padding: 0.5em 1em;
        border-radius: 6px;
        cursor: pointer;
        color: #c08000;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-left: 0.5em;
        }
    }

    .left {
        width:55%;
        .header {
            width:100%;
            .wallet-buttons { 
                background-image: linear-gradient(to bottom, #231f20, #070607);
                padding:5px 10px;
                border-radius:10px;
                .btn-wallet { 
                    color:white; 
                    font-family:OpenSans-B;
                    border:none;
                    // font-size:15px;
                    &.active {
                    color:#c08000;
                    }
                }
            }
                
            .search {
                width:400px;

                .search-input {
                    background-image: linear-gradient(to bottom, #231f20, #070607);
                    color:white;
                    border:none;

                    &::placeholder {
                    color:#444444;
                    font-family:OpenSans;
                    }
                }

                .input-group-append {
                    background-image: linear-gradient(to bottom, #231f20, #070607);
                    
                    .btn-search {
                    color:#444444;
                    }
                }
            }
        }

        .main-wrapper { 
            width:100%;


              

            .wallet-row {
                margin-top:40px;
                .info-card {
                    background-image:url(../../assets/wallet.png);
                    background-size:cover;
                    background-repeat:no-repeat;
                    width:420px;
                    height:270px;
                    padding:50px 20px;

                    .wallet-address {

                    }

                    .label {
                        color:#c08000;
                        font-family:OpenSans-SB;
                    }

                    .currency {
                        font-size:25px;
                        font-family:OpenSans-SB;
                        color:#c08000;

                        .balance {
                            color:#a3a3a3;
                        }
                    }
                }

                // .function-row {
                //     width:700px;
                // }
                
                .function-card {
                    background-image: linear-gradient(to bottom, #231f20, #070607);
                    padding:30px 20px;
                    width:175px;
                    height:200px;
                    border-radius:15px;
                    font-family:OpenSans;

                    .function-img {
                        img {
                            width:70px;
                        }
                    }
                }
            }

            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
                color: #333;
            }
            .hash {
                white-space: nowrap !important;
                width: 20em;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
            }

            td, th {
            
                text-align: left;
                padding: 1em;
                font-size: 0.75em;
            }

            td {  
                background-image: linear-gradient(to bottom, #231f20, #070607);
                color: #FFF;
                border-bottom: 1px solid #FFF;
                padding: 1.5em;
            
            }

            th {
                
                background-image: linear-gradient(to bottom, #231f20, #070607);
                color: #c08000;
            }

            th:first-child { 
                border-top-left-radius: 6px;
            }
            th:last-child { 
                border-top-right-radius: 6px;
            }

            .history-content {
                background: #FFF; 
                border-radius: 6px;
                overflow-x:auto;
                margin-top:40px;
            }
        }
    }

    .right {
        width:40%;

        .main-wrapper {
              h6 {
                    margin-top: 1em;
                    margin-bottom: 1em;
                    font-size: 1.25em;
                }

                .countdown-time{
                  background: transparent !important;
                    border-radius: 8px;
                    padding: 8px;
                    -ms-flex-pack: justify;
                    justify-content: space-evenly;
                    margin-bottom: 24px;
                    z-index: 9999 !important;
                }

                .card-countdown{
                    width: 7rem;
                    height: 5rem;
                    background-color: #c68b17 !important;
                    color: white;
                    padding: 0.5em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  .countdown-time-num{
                    font-weight: bolder;
                    font-size: 1.6rem;
                  }

        }
    }

    .recent-transactions {
            margin-top: 1em;
            background-image: linear-gradient(to bottom, #231f20, #070607);
            padding:30px;
            border-radius:10px;

            .heading {
                color:#c08000;
                font-family:OpenSans-SB;
                font-size:20px;
                margin-bottom:1em;
            }

            .vested-info {
                img {
                    object-fit: contain;
                    width:40px;
                }

                .vested-text {
                    margin-left:0.5em;

                    .vested-value {
                        font-family:OpenSans-SB;
                        font-size:25px;
                        color:#c08000;
                        margin-bottom:0px;
                    }

                    .label {
                        margin-bottom:0px;
                    }
                }
            }

            .amount {
                .form-control {
                    background-image: linear-gradient(to bottom, #231f20, #070607);
                    border:none;
                    height:50px;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    color:#c08000;
                    font-family:OpenSans-SB;
                }

                .input-group-append {
                    width:60px;
                    padding:10px;
                    background-image: linear-gradient(to bottom, #231f20, #070607);
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
        }

    .vesting-sched-wrapper {
        width:100%;

        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
            color: #333;
        }
        .hash {
            white-space: nowrap !important;
            width: 20em;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
        }

        td, th {
        
            text-align: left;
            padding: 1em;
            font-size: 0.75em;
        }

        td {  
            background-image: linear-gradient(to bottom, #231f20, #070607);
            color: #FFF;
            border-bottom: 1px solid #FFF;
            padding: 1.5em;
        
        }

        th {
            
            background-image: linear-gradient(to bottom, #231f20, #070607);
            color: #c08000;
        }

        th:first-child { 
            border-top-left-radius: 6px;
        }
        th:last-child { 
            border-top-right-radius: 6px;
        }
    }

    .heading {
        color:#c08000;
        font-family:OpenSans-SB;
        font-size:20px;
        margin-bottom:1em;
    }
</style>

