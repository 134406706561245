<template>
    <div class="d-flex flex-wrap justify-content-between">
        <div class="left">
            <div class="header d-flex flex-wrap justify-content-between">
                <div class="d-flex flex-wrap justify-content-around wallet-buttons">
                    <button class="btn btn-wallet" :class="{active: init.dashboardState == 'lockedtokens'}" @click="initFunction('lockedtokens')">{{ $t('home.lockedTokens') }}</button>
                    <button class="btn btn-wallet" :class="{active: init.dashboardState == 'mainwallet'}" @click="initFunction('mainwallet')">{{ $t('home.wallet') }}</button>
                     <button class="btn btn-wallet" :class="{active: init.dashboardState == 'secondarywallet'}" @click="initFunction('secondarywallet')">{{ $t('home.secondaryWallet') }}</button>
                    <!-- <button class="btn btn-wallet" :class="{active: init.dashboardState == 'mainwallet'}" @click="initFunction('mainwallet')">NFTs</button> -->
                </div>
                <!-- <div class="search input-group">
                    <input type="text" class="form-control search-input" placeholder="Search transaction">
                    <div class="input-group-append d-flex flex-column justify-content-center">
                    <button class="btn btn-search">
                        <b-icon icon="search" style="cursor:pointer;"></b-icon>
                    </button>
                    </div>
                </div> -->
            </div>
            <div class="main-wrapper">
                <div class="wallet-row d-flex flex-wrap justify-content-between">
                    <div class="info-card d-flex flex-wrap flex-column justify-content-center mb-5">
                       <div class="wallet-address mt-5">{{ init.wallet.address }}</div>
                        <div class="label">{{ $t('home.walletBalance') }}</div>
                        <div class="currency">HOMS <span class="balance">{{ formatAmount(bonusFunds.availableBalance) }}</span></div>
                    </div>
                    <div class="function-row d-flex flex-wrap justify-content-around mt-5">
                        <div class="function-card text-center text-white d-flex flex-column justify-content-around" @click="initFunction('sendBonus')">
                            <div class="function-img">
                                <img src="../../assets/send.png" alt="">
                            </div>
                            <div class="label">{{ $t('home.send') }}</div>
                        </div>
                       
                    </div>
                </div> 
            </div>
        </div>
        <div class="right">
            <!-- <div class="recent-transactions">
                <div class="heading">Recent Transactions</div>
                <p v-if="history.transactions.length == 0">No transactions found</p>
                <div class="transaction-row success" v-for="item in history.transactions" :key="item.block_hash">
                    <div class="row-text">{{ item.timestamp }}</div>
                    <div class="row-text">{{ item.tx_hash | truncate(20) }}</div>
                    <div class="row-text">{{ formatAmount(item.total.value) }}</div>
                </div>
            </div> -->
            <div class="recent-transactions mt-4">
                <div class="heading">{{ $t('home.recentTransactions') }}</div>
                <p v-if="historyList.length == 0">{{ $t('home.noTransactions') }}</p>
                <div v-for="item in historyList" :key="item.txnId">
                    <div class="transaction-row success" v-if="item.txnStatus == 'SUCCESS'">
                        <div class="row-text">{{ item.txnId }}</div>
                        <div class="row-text">{{ item.cryptoRefId | truncate(20) }}</div>
                        <div class="row-text">{{ formatAmount(item.headerAmount) }} {{ item.currency }}</div>
                    </div>
                    <div class="transaction-row failed" v-if="item.txnStatus == 'FAILED'">
                        <div class="row-text">{{ item.txnId }}</div>
                        <div class="row-text">{{ item.cryptoRefId | truncate(20) }}</div>
                        <div class="row-text">{{ formatAmount(item.headerAmount) }} {{ item.currency }}</div>
                    </div>
                    <div class="transaction-row pending" v-if="item.txnStatus == 'PENDING'">
                        <div class="row-text">{{ item.txnId }}</div>
                        <div class="row-text">{{ item.cryptoRefId | truncate(20) }}</div>
                        <div class="row-text">{{ formatAmount(item.headerAmount) }} {{ item.currency }}</div>
                    </div>
                </div>
                <div class="d-flex justify-content-between text-white">
                    <div></div>
                    <a class="link" href="/history">{{ $t('home.viewAll') }}</a>
                </div>
            </div>
            <div class="recent-transactions mt-4">
                <div class="heading">{{ $t('home.recentTransfers') }}</div>
                <p v-if="blockchainHistoryList.length == 0 && !isBlockchainHistoryLoading">{{ $t('home.noTransfers') }}</p>
                <div v-for="item in blockchainHistoryList" :key="item.txnId" v-if="item.method == 'transfer' && !isBlockchainHistoryLoading">
                    <div class="transaction-row success">
                        <div class="row-text">{{ item.timestamp }}</div>
                        <div class="row-text">{{ item.tx_hash | truncate(20) }}</div>
                        <div class="row-text">{{ formatAmount(item.total.value/toWei) }} HOMS</div>
                    </div>
                </div>
                <div class="loader-container" v-if="isBlockchainHistoryLoading">
                    
                    <img class="loader" src="@/assets/loading.gif"/>
                </div>
                <div class="d-flex justify-content-between text-white">
                    <div></div>
                    <a class="link" href="/history">{{ $t('home.viewAll') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import numeral from 'numeral' 
import ethers from 'ethers' 

export default {
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    props: {
        state: '',
        history: []
    },
    data() {
        return {
            historyList: [],
            isBlockchainHistoryLoading: true,
            blockchainHistoryList: [],
            toWei: 1000000000000000000,
            bonusFunds: {
                availableBalance: 0
            }
        }
    },
    methods: {
        setState() {
            this.$emit('setState')
        },

        formatAmount: function(num){
            return numeral(num).format('0,0.00000000');
        },

        getBuyHistory() {

  
            const options = {
                method: 'GET',
                url: '/cx/txn/cryptoreceive',
            };

            this.axios(options)
            .then((response) => {
               console.log(response.data)
               this.historyList = response.data;
                console.log(this.historyList)
            })
        },
        initFunction(func) {
            this.$emit(func)
        },

         getBonusBalance() {

  
            const options = {
                method: 'GET',
                url: '/cx/funds',
            };

            this.axios(options)
            .then((response) => {
             

               this.bonusFunds = response.data.filter(fund => fund.currency === "HOMSB")[0];


               if(this.bonusFunds == undefined) {
                this.bonusFunds = {};
                this.bonusFunds.availableBalance = 0;
               }


            this.init.wallet.bonusBalance = this.bonusFunds.availableBalance;
               

            })
        },

        getHistory: function() {
 

                let smartContract = this.init.isTest ? "0x3219B80A6Cf1050aCd84D7B73fb03237556BeE21" : "0x8D377F0F1E2B7bcDcC1d6Db371C61DC7cF3de808";


                    const params = {
                      type: 'ERC-20,ERC-721,ERC-1155',
                      filter: 'to | from',
                      token: smartContract
                    }

                    const data = Object.keys(params)
                        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                        .join('&');

                    const options = {
                        method: 'GET',
                        params,
                        headers: {
                            'accept': 'application/json' ,
                            'content-type': 'application/json' ,
                        },
                        url: '/blxq-api/v2/addresses/' + this.init.wallet.address + '/token-transfers',
                    };

                    this.axios(options)
                      .then((response) => { 
                          this.blockchainHistoryList = response.data.items;
                          this.isBlockchainHistoryLoading = false;
                      }).catch((err) => {
                        console.log(err);
                        this.blockchainHistoryList = [];
                      })
                  },
    },
     mounted() {
        this.getBonusBalance();
        this.getBuyHistory();
        this.getHistory();

 

    }
}
</script>

<style scoped lang="scss">
.loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .left {
        width:70%;
        .header {
            width:100%;
            .wallet-buttons { 
                background-image: linear-gradient(to bottom, #231f20, #070607);
                padding:5px 10px;
                border-radius:10px;
                .btn-wallet { 
                    color:white; 
                    font-family:OpenSans-B;
                    border:none;
                    // font-size:15px;
                    &.active {
                    color:#c08000;
                    }
                }
            }
                
            .search {
                width:400px;

                .search-input {
                    background-image: linear-gradient(to bottom, #231f20, #070607);
                    color:white;
                    border:none;

                    &::placeholder {
                    color:#444444;
                    font-family:OpenSans;
                    }
                }

                .input-group-append {
                    background-image: linear-gradient(to bottom, #231f20, #070607);
                    
                    .btn-search {
                    color:#444444;
                    }
                }
            }
        }

        .main-wrapper { 
            width:100%;
            .wallet-row {
                margin-top:40px;
                .info-card {
                    background-image:url(../../assets/wallet.png);
                    background-size:cover;
                    background-repeat:no-repeat;
                    width:420px;
                    height:270px;
                    padding:50px 20px;

                    .wallet-address {

                    }

                    .label {
                        color:#c08000;
                        font-family:OpenSans-SB;
                    }

                    .currency {
                        font-size:25px;
                        font-family:OpenSans-SB;
                        color:#c08000;

                        .balance {
                            color:#a3a3a3;
                        }
                    }
                }

                .function-row {
                    width:700px;
                }
                
                .function-card {
                    background-image: linear-gradient(to bottom, #231f20, #070607);
                    padding:30px 20px;
                    width:175px;
                    height:200px;
                    border-radius:15px;
                    font-family:OpenSans;

                    .function-img {
                        img {
                            width:70px;
                        }
                    }
                }
            }

            .chart {
                width:100%;
                margin-top:40px;
            }
        }
    }

    .right {
        width:350px;

        .recent-transactions {
            background-image: linear-gradient(to bottom, #231f20, #070607);
            padding:30px;
            border-radius:10px;

            .heading {
                color:#c08000;
                font-family:OpenSans-SB;
                font-size:20px;
                margin-bottom:1em;
            }

            .transaction-row {
                margin:20px 0px;
                padding-left:15px;

                &.success {
                    border-left:3px green solid;
                }

                &.failed {
                    border-left:3px red solid;
                }

                &.pending {
                    border-left:3px yellow solid;
                }
            }
        }

        .link {
            color:white;
            text-decoration: none;
            &:hover {
                text-decoration:underline;
                cursor:pointer;
            }
        }
    }

    @media screen and (max-width:1700px) {
        .left {
            .main-wrapper {
                .wallet-row {
                    justify-content:center !important;
                }
            }
        }
    } 
</style>


