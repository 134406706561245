<template>
	<b-modal id="buy-modal" hide-footer  hide-header-close no-close-on-backdrop size="lg" >

		<template #modal-header> 
		
				<div>
					<v-icon name="chevron-left" @click="close()" class="icon" v-if="state != 'success'"/>
				</div>
				<h5>
					{{ $t('modal.buy.buyHoms') }}
				</h5> 
				<div></div>

		</template>
		<div v-if="state == 'network'">
			<div v-for="network in networkList" :key="network.id">
				<button class="btn btn-network" @click="selectNetwork(network.refCode)">{{ network.refCode }} {{ $t('modal.buy.network') }}</button>
			</div>
			<!-- <button class="btn btn-network" @click="selectNetwork('ERC20')">ERC20 Network</button>
			<button class="btn btn-network" disabled>TRC20 Network</button>
			<button class="btn btn-network" disabled>BEP20 (BSC) Network</button> -->
		</div>
		<div v-if="state == 'deposit'">
			<label for="" class="mr-3 mx-3">{{ $t('modal.buy.selectNetwork') }}:</label>
			<b-form-select v-model="network" class="mb-3 ml-3" @change="selectNetwork()">
				<b-form-select-option v-for="network in networkList" :key="network.refCode" :value="network.refCode">{{ network.refCode }}</b-form-select-option>
			</b-form-select>
			<div class="mx-auto text-center d-flex justify-content-center align-items-center" v-if="depositAddress != ''">
				<qrcode v-bind:value="depositAddress" v-bind:options="{ width: 300 }" class="qrCanvas mx-auto"></qrcode>
			</div>

			<p>{{ $t('modal.buy.scanQr') }} :</p>

			<div class="input-group">
				<input type="text" class="form-control" id="addressValue" v-model="depositAddress" readonly>
				<div class="input-group-btn">
					<button class="btn btn-main" type="button" id="tooltip-target-1" style="margin:0;" v-on:click="copy">{{ $t('modal.buy.copy') }}</button>
					<b-tooltip target="tooltip-target-1" triggers="click">
						{{ $t('modal.buy.copied') }}
					</b-tooltip>
				</div>
			</div>

			<div class="send-container mt-4" style="display:block;">
				<form v-on:submit.prevent="onSend()">
						<div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
						<div class="form-container">
							<label for="ampunt">{{ $t('modal.buy.payUsdt') }}:</label>
							<input type="text" id="amount" name="amount" v-model="buy.amountInUSDT" class="form-control" @change="getRate()">
							<span class="error" v-if="errors.amountInUSDT != ''"> {{ errors.amountInUSDT }} </span> 
						</div>
					
						<div class="form-container mt-2">
							<label for="receipt">{{ $t('modal.buy.payHoms') }}:</label>
							<input type="text" id="receipt" name="receipt" v-model="buy.amountInHOMS" class="form-control" readonly>
							<span class="error" v-if="errors.amountInHOMS != ''"> {{ errors.amountInHOMS }} </span> 
						</div>

						<!-- <div class="form-container mt-2">
							<label for="ampunt">Sender Wallet Address:</label>
							<input type="text" id="amount" name="amount" v-model="senderWalletAddress" class="form-control" maxlength="42">
							<span class="error" v-if="errors.amountInUSDT != ''"> {{ errors.amountInUSDT }} </span> 
						</div> -->

						<div class="form-container mt-2">
							<label for="ampunt">{{ $t('modal.buy.transactionHash') }}:</label>
							<input type="text" id="amount" name="amount" v-model="cryptoRefId" class="form-control" maxlength="66">
							<span class="error" v-if="errors.amountInUSDT != ''"> {{ errors.amountInUSDT }} </span> 
						</div>

					<div class="flex  margin-top">
						<button  class="btn btn-main mt-3" type="submit">  
							<span>{{ $t('modal.buy.send') }}</span>	
						</button>
						
					</div>
				</form>
			</div> 
		</div>
		<div class="text-center" v-if="state == 'success'">
			<h4>{{ $t('modal.buy.requestSent') }}</h4>
			<button  class="btn btn-main mt-3" @click="closeReload()">  
				<span>{{ $t('modal.buy.done') }}</span>	
			</button>
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
export default {
  name: 'Send',
  data: function() {
      return  {
      	send: {
			address: "",
			amount: "",
			privateKey: ""
		},
		buy: {
			amountInHOMS: '',
			amountInUSDT: ''
		},
		showError: false,
		errors: {
			address: "",
			amount: "",
			privateKey: "",
			amountInHOMS: '',
			amountInUSDT: ''
		},
		network: '',
		networkList: {},
		state: 'deposit',
		ifResent: true,
		timerInSeconds: 60,
		pinArr: [],
		otpArr: [],
		systemMsg: "",
		showError: false,
		successInfo: {},
		isOtpDisabled: false,
		isSendDisabled: false,
		txnControlId: '',
		cryptoRefId: '',
		depositAddress: '',
		senderWalletAddress: ''
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		close: function(){
   			this.$bvModal.hide('buy-modal'); 
			this.state = 'deposit'
			this.pinArr = []
			this.otpArr = []
			this.errors.address = ""
			this.errors.amount = ""
			this.errors.privateKey = ""
			this.isOtpDisabled = false
   		},
		closeReload: function(){
			
			window.location.reload();
		},
   		formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },

	  nextStep() {
		  let hasError = false
		  if(this.send.address == "") {
			  hasError = true
			  this.errors.address = this.i18n.t('modal.buy.errors.isRequired')
		  }
		  if(this.send.amount == "") {
			  hasError = true
			  this.errors.amount = this.i18n.t('modal.buy.errors.isRequired')	
		  }

		  if(hasError == false) {
		this.state = 'pin'
		this.errors.address = ""
		this.errors.amount = ""
		this.errors.privateKey = ""
		  } else {
			  console.log(hasError)
		  }
	  },

	  resendCode: function() {
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/private/resend2fa',
          };

          this.axios(options)
            .then((response ) => { 
              this.systemMsg = ""
              this.showError = false
              this.ifResent = true
              console.log(this.ifResent)
              this.timer();
            }).catch((err) => {
                console.log('resend code errror')
                console.log(err)
            })
        },

      timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },

		verifyPin() {
            const params = {
                pin: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validatepin',
                };
                this.axios(options)
                    .then((response) => {
						this.state = 'otp'
                      this.clearError();
                      this.timer();
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.pinArr = []
						this.showError = true
						this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
						},10000);
					});

        },

		clearError() {
			this.systemMsg = ""
			this.showError = false
		},

		verifyOtp() {
			this.isOtpDisabled = true
			const params = {
                otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validate2fa',
                };
                this.axios(options)
                    .then((response) => {
                     this.confirmSend();
					 this.clearError();
                    })
                    .catch((error) => {
                        // Error code goes here
						this.isOtpDisabled = false
						this.showError = true
            			this.otpArr = []
                        this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
					},10000);
				});
        },

		isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },

	  copy: function(){
   			var copy = document.getElementById("addressValue");

        copy.select();
        copy.setSelectionRange(0, 99999);

        document.execCommand("copy"); 
   		},

      otpBack: function(event, currrentBox) {
		if(this.state == 'pin') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("pin"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("pin"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		} else if(this.state == 'otp') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		}
      },

	  selectNetwork() {
		console.log('switch')
		this.networkList.forEach((value) => {
			if(value.refCode == this.network) {
				this.depositAddress = value.refValue
			}
		})
	  },

	  getNetworks: function(){
        const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/pub/homs/usdt',
        };

        this.axios(options)
          .then((response) => {
              this.networkList = response.data
          })
      },

	  getRate() {
		const params = {
			amount: this.buy.amountInUSDT,
			fromCurrency: 'USDT',
			toCurrency: 'HOMS'
		}

		const data = Object.keys(params)
			.map((key) => `${key}=${encodeURIComponent(params[key])}`)
			.join('&');
		
		const options = {
			method: 'POST',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			data,
			url: '/pub/getxchgrate',
		};
		this.axios(options)
			.then((response) => {
				
			})
			.catch((error) => {
				
			});

		this.buy.amountInHOMS = this.buy.amountInUSDT / 0.03
	  },

      onSend() {
		let hasError = false 
		
		if(this.buy.amountInUSDT == '') {
			hasError = true
			this.showError = true
			this.systemMsg = this.i18n.t('modal.buy.errors.noUSDTAmount')	
		}

		// if(this.senderWalletAddress.length < 42) {
		// 	hasError = true
		// 	this.showError = true
		// 	this.systemMsg = 'Invalid Sender Address'
		// }

		if(this.cryptoRefId.length < 66) {
			hasError = true
			this.showError = true
			this.systemMsg = this.i18n.t('modal.buy.errors.invalidHash')	
		}

		if(!hasError) {
			const options = {
			method: 'POST',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			url: '/cx/txn/cryptoreceive/init',
		};
		this.axios(options)
			.then((response) => {
				let txnControlId = response.data.txnControlId
				const params = {
					cryptoRefId: this.cryptoRefId,
					manual: true,
					sourceAmount: this.buy.amountInUSDT,
					sourceCurrency: 'USDT',
					txnControlId: txnControlId,
					sourceWalletAddress: this.init.wallet.address,
					targetWalletAddress: this.depositAddress
				}

				const data = Object.keys(params)
					.map((key) => `${key}=${encodeURIComponent(params[key])}`)
					.join('&');
				
				const options = {
					method: 'POST',
					headers: { 'content-type': 'application/x-www-form-urlencoded' },
					data,
					url: '/cx/txn/cryptoreceive/submit',
				};
				this.axios(options)
					.then((response) => {
						this.confirmSend(txnControlId);
						this.clearError();
					})
					.catch((error) => {
						// Error code goes here
						this.isOtpDisabled = false
						this.showError = true
						this.otpArr = []
						this.systemMsg = error.response.data.msgText
						if(error.response.data.fieldErrors) {
							this.systemMsg = error.response.data.fieldErrors[0].defaultMessage
						}
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
					},10000);
				});
			})
			.catch((error) => {
				// Error code goes here
				this.isOtpDisabled = false
				this.showError = true
				this.otpArr = []
				this.systemMsg = error.response.data.msgText
				console.log(error.response.data.msgText)
				setTimeout(()=>{
				this.clearError()
			},10000);
		});
		}
		
	  },

      	confirmSend: function(txnControlId){
			const params = {
				txnControlId: txnControlId
			}

			const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
			
			const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded' },
				data,
				url: '/cx/txn/cryptoreceive/confirm',
			};
			this.axios(options)
				.then((response) => {
					this.state = 'success'
				})
				.catch((error) => {
					
				});
		},
   	},
   	beforeMount(){
		   
		   this.getNetworks();
 		},

}
</script>
 
<style scoped lang="scss">
	  .send-container { 
	   
	    border-radius: 6px;
	    flex-direction: column;
	    position: relative;
	    background: #FFF; 
	    color: #000;
  }

  

   .error {
  	color: red;
  }

  button {
  	margin-top:1em;
  }

  .btn-send {
   background-color: #c08000 !important; 
   color:white;
   width:100%;
   margin-top:10px;
 }

  .otp-wrapper, .pin-wrapper {
      display: flex;
      width: 80%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
			  padding-bottom:7.5px;
      }
    }
	}

	.btn-network {
		background-color:#0e0e0e;
		border:2px white solid;
		border-radius:15px;
		color:white;
		width:100%;
	}


	
</style>

